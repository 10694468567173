// adapted from the "with-redux" nextjs sample

import React from "react"
import { Store } from "redux"

const isServer = typeof window === "undefined"

declare global {
  interface Window {
    __NEXT_REDUX_STORE__: Store
  }
}

export const withReduxStore_Factory = (initializeStore: (overrideInitialState?: any) => Store) => {
  function getOrCreateStore(initialState?: any) {
    if (isServer) {
      return initializeStore(initialState)
    }
    if (!window.__NEXT_REDUX_STORE__) {
      window.__NEXT_REDUX_STORE__ = initializeStore(initialState)
    }
    return window.__NEXT_REDUX_STORE__
  }

  const withReduxStore = (App: any) => {
    return class AppWithRedux extends React.Component<any, any> {
      static async getInitialProps(appContext: any) {
        const reduxStore = getOrCreateStore()
        appContext.ctx.reduxStore = reduxStore

        let appProps = {}
        if (typeof App.getInitialProps === "function") {
          appProps = await App.getInitialProps.call(App, appContext)
        }

        return {
          ...appProps,
          initialReduxState: reduxStore.getState(),
        }
      }

      reduxStore: Store

      constructor(props: any) {
        super(props)
        //console.log("init with state", props.initialReduxState)
        this.reduxStore = getOrCreateStore(props.initialReduxState)
      }

      render() {
        return <App {...this.props} reduxStore={this.reduxStore} />
      }
    }
  }

  return withReduxStore
}
