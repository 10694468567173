import { RedutserShort } from "redutser/built/redutser"
import { createStore, applyMiddleware, compose } from "redux"
import { asyncThunkErrorMw, formatError } from "@proerd/nextpress-client/lib/error"
import thunkMw from "redux-thunk"

type Reduts = RedutserShort<any, any> & { reducerWithInitializer: any }

export const getStore = <Red extends Reduts>(root: Red) => (customState?: Red["initialState"]) => {
  const composeEnhancers =
    (typeof window !== "undefined" && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose
  const store = createStore(
    customState ? root.reducerWithInitializer(customState) : root.reducer,
    composeEnhancers(
      applyMiddleware(
        // catches errors from reducers + thunks and emits an error action from them
        asyncThunkErrorMw(_generateErrorAction),
        thunkMw.withExtraArgument(root)
      )
    )
  )

  return store
}

export function _generateErrorAction(err: any) {
  return {
    type: "controller",
    payload: {
      type: "error",
      payload: formatError(err),
    },
  }
}
