import { url } from "@common/url"
import { rootEffects } from "@common/root-store"

export async function requestJson<T = unknown>(what: string, payload: Record<string, any>) {
  console.log("requesting", what, payload)
  let token: any = localStorage.getItem("user_token")
  const headers = { "content-type": "application/json", ...(token ? { authorization: token } : {}) }
  const resp = await fetch(url(what), {
    method: "POST",
    body: JSON.stringify(payload),
    headers,
  })
  if (resp.status === 401 && window.location.pathname !== "/") {
    ;(rootEffects.logout as any)()()
  }
  const cloned = resp.clone()
  try {
    var json = await resp.json()
  } catch (err) {
    try {
      var text = await cloned.text()
      throw text
    } catch (err2) {
      throw err2
    }
    //throw text
  }
  if (String(resp.status).charAt(0) !== "2") throw json
  return json as T
}
