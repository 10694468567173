import App, { Container } from "next/app"
import React from "react"
import { withReduxStore_Factory } from "@common/with-redux-store"
import { getStore } from "@common/get-store"
import { root } from "@common/root-store"
import { Provider } from "react-redux"
import "@common/main.scss"
import "@common/yup-locale"

class MyApp extends App {
  render() {
    const { Component, pageProps, reduxStore } = this.props
    return (
      <Container>
        <Provider store={reduxStore}>
          <Component {...pageProps} reduxStore={reduxStore} />
        </Provider>
      </Container>
    )
  }
}

const withReduxStore = withReduxStore_Factory(getStore(root))

export default withReduxStore(MyApp)
