import { createRedutser } from "redutser"
import { requestJson } from "@common/request-json"

export const initialState = {
  users: undefined as undefined | T.User[],
}

export const reducer = createRedutser(initialState, {
  replaceUsers: (state, act: { users: T.User[] }) => {
    return {
      users: act.users,
    }
  },
})

export const effects = {
  loadUsers: () => async (dispatch: DefaultDispatch, getState: any, root: Root) => {
    let { users } = await requestJson<{ users: T.User[] }>("/api/user/list", {})
    dispatch(root.creators.users.replaceUsers({ users }))
  },
}
