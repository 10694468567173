import { setLocale } from "yup"

export let mixed = {
  default: "${path} é inválido",
  required: "${path} deve ser preenchido",
  oneOf: "${path} deve ter um dos valores: ${values}",
  notOneOf: "${path} não pode ser nenhum destes valores: ${values}",
}

export let string = {
  length: "${path} deve ter exatamente ${length} caracteres",
  min: "${path} deve ter ao menos ${min} caracteres",
  max: "${path} deve ter no máximo ${max} caracteres",
  matches: '${path} deve satisfazer a expressão regular: "${regex}"',
  email: "${path} deve ser um email válido",
  url: "${path} deve ser um URL válido",
  trim: "${path} não pode conter espaços extras",
  lowercase: "${path} deve estar em caixa baixa",
  uppercase: "${path} deve estar em caixa alta",
}

export let number = {
  min: "${path} deve ser maior ou igual a ${min}",
  max: "${path} deve ser menor ou igual a ${max}",
  lessThan: "${path} deve ser menor que ${less}",
  moreThan: "${path} deve ser maior que ${more}",
  notEqual: "${path} não pode ser igual a ${notEqual}",
  positive: "${path} deve ser um número positivo",
  negative: "${path} deve ser um número negativo",
  integer: "${path} deve ser um número inteiro",
}

export let date = {
  min: "${path} deve ser posterior a ${min}",
  max: "${path} deve ser anterior a ${max}",
}

export let boolean = {}

export let object = {
  noUnknown: "${path} não pode conter propriedades não especificadas no formato do objeto",
}

export let array = {
  min: "${path} deve possuir ao menos ${min} itens",
  max: "${path} deve possuir no máximo ${max} itens",
}

setLocale({
  array,
  object,
  date,
  number,
  string,
  mixed,
})
