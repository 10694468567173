import { getStore } from "@common/get-store"
import { createRedutser, subdomain, liftRedutserState } from "redutser"
import * as Users from "../users/store"

const initialState = {
  users: Users.initialState,
  error: undefined as any,
}

type State = typeof initialState

const controller = createRedutser(initialState, {
  error: (state, error: string): State => {
    return {
      ...state,
      error,
    }
  },
})

export const root = subdomain(initialState, {
  users: liftRedutserState(initialState, "users", Users.reducer),
  controller,
})

export const rootStore = getStore(root)

export const rootEffects = {
  logout: () => async (dispatch: DefaultDispatch, getState: any, root: Root) => {
    localStorage.removeItem("user_token")
    localStorage.removeItem("user_info")
    window.location.href = process.env.WEBSITE_ROOT!
  },
}
